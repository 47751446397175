@tailwind base;
@tailwind components;
@tailwind utilities;

main {
  overflow: hidden;
  height: 100%;
}

@media only screen and (min-width: 1080px){
  main {
    max-height: calc(100vh - 340px);
  }
}

@media only screen and (min-width: 2160px){
  main {
    max-height: calc(100vh - 560px);
  }
}